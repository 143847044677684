<template>
    <component :is="markup" :title="$t('auth.signup.title')" :subtitle="$t('auth.signup.subtitle')">
        <div class="grid grid-cols-2 gap-4">
            <v-form-group :label="$t('label.firstname')" :v="v$.form.firstname" class="mb-4">
                <v-form-input v-model="form.firstname" />
            </v-form-group>

            <v-form-group :label="$t('label.lastname')" :v="v$.form.lastname" class="mb-4">
                <v-form-input v-model="form.lastname" />
            </v-form-group>
        </div>

        <v-form-group :label="$t('label.mobile')" :v="v$.form.mobile" class="mb-4">
            <v-form-input v-model="form.mobile" type="tel" />
        </v-form-group>

        <v-form-group :label="$t('label.email')" :v="v$.form.email" class="mb-4">
            <v-form-input v-model="form.email" type="email" />
        </v-form-group>

        <div class="grid grid-cols-2 gap-4">
            <v-form-group :label="$t('label.password')" :v="v$.form.password" class="mb-4">
                <v-form-input v-model="form.password" type="password" />
            </v-form-group>

            <v-form-group :label="$t('label.password_confirmation')" :v="v$.form.password_confirmation" class="mb-4">
                <v-form-input v-model="form.password_confirmation" type="password" />
            </v-form-group>
        </div>

        <template #footer>
            <v-button type="submit" variant="primary" class="w-full" :loading="loading" @click="handleSignup">
                {{ $t('action.signup') }}
            </v-button>

            <p class="text-center pt-8 text-blue-gray-500">
                {{ $t('have_account') }}

                <v-button v-if="markup === 'v-modal'" class="text-blue-600 hover:underline" @click="$emit('abort')">
                    {{ $t('action.signin') }}
                </v-button>

                <localized-link v-else :to="{ name: 'login' }" class="text-blue-600 hover:underline">
                    {{ $t('action.signin') }}
                </localized-link>
            </p>
        </template>
    </component>
</template>

<script>
import { mapActions } from 'vuex';

import { useVuelidate } from '@vuelidate/core';
import { required, email, minLength, sameAs } from '@vuelidate/validators';
import { phone } from '@/assets/javascript/vuelidate.js';

export default {
    props: {
        markup: {
            type: String,
            default: 'auth-layout'
        } 
    },

    emits: ['confirm', 'abort'],

    setup () {
        return { v$: useVuelidate() }
    },

    data: () => ({
        loading: false,
        form: {
            firstname: null,
            lastname: null,
            mobile: null,
            email: null,
            password: null,
            password_confirmation: null
        }
    }),

    validations () {
        return {
            form: {
                firstname: { required },
                lastname: { required },
                mobile: { required, phone },
                email: { required, email },
                password: { required, minLength: minLength(8) },
                password_confirmation: { sameAsPassword: sameAs(this.form.password) }
            }
        }
    },

    methods: {
		...mapActions('auth', [
            'signup',
		]),

        handleSignup (event) {
            event.preventDefault();

			this.v$.form.$touch();

            if (this.v$.form.$invalid) return;

            this.loading = true;

			this.signup(this.form).then(() => {
                if (this.markup === 'v-modal') this.$emit('confirm');
                else this.$redirect('login');
			}).catch(() => {
                this.loading = false;
            });
		},
    }
};
</script>
