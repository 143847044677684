<template>
    <frontoffice-layout>
        <div class="bg-blue-gray-200 h-screen--with-header pt-8 pb-4 px-4 flex justify-end relative <md:h-auto <md:p-0 <md:flex-col <md:justify-start">
            <v-map class="h-full w-full absolute top-0 left-0 <md:h-105 <md:relative" :origin="travel.pickup_address" :destination="travel.dropoff_address" :waypoints="travel.waypoints" />
            
            <aside class="bg-white shadow-lg max-w-124 w-full rounded pt-5 pb-3 flex flex-col relative <md:max-w-none">
                <v-heading level="h4" class="mb-4 px-5 flex-shrink-0">
                    {{ $t('action.book_trip') }}
                </v-heading>

                <div class="overflow-y-auto flex-1">
                    <div class="px-5">
                        <ul class="bg-white rounded-1xl flex items-center shadow-md justify-between p-4 mb-6">
                            <li class="flex items-center font-medium">
                                <v-icon name="schedule" class="mr-2 text-blue-gray-300" />

                                {{ booking.duration ? $dayjs().startOf('day').add(booking.duration, 'second').format('H[h]mm[min]') : '--' }}
                            </li>

                            <li class="flex items-center font-medium">
                                <v-icon name="map" class="mr-2 text-blue-gray-300" />

                                {{ booking.distance ? `${(Math.ceil(booking.distance / 1000))}km` : '--' }}
                            </li>
                            
                            <li class="flex items-center font-medium">
                                <v-icon name="euro" class="mr-2 text-blue-gray-300" />
                                {{ booking.price ? booking.price : '--' }}
                            </li>
                        </ul>

                        <v-form-group :label="$t('label.origin')" class="mb-4" :v="v$.form.pickup_address">
                            <v-form-places v-model="travel.pickup_address" />
                        </v-form-group>

                        <template v-for="(waypoint, idx) in travel.waypoints" :key="idx">
                            <v-form-group :label="$t('label.waypoint', { index: idx + 1 })" class="mb-4">
                                <div class="relative">
                                    <v-form-places v-model="travel.waypoints[idx]" class="pr-8" />

                                    <v-button icon="remove" class="text-gray-600 absolute right-2 bottom-0 top-0 my-auto leading-none" @click="travel.waypoints.splice(idx, 1)" />
                                </div>
                            </v-form-group>
                        </template>

                        <v-form-group :label="$t('label.destination')" class="mb-4" :v="v$.form.dropoff_address">
                            <div class="relative">
                                <v-form-places v-model="travel.dropoff_address" />

                                <v-button v-if="form.pickup_address && form.dropoff_address && travel.waypoints.length < 3" icon="add" class="text-gray-600 absolute right-2 bottom-0 top-0 my-auto leading-none" @click="travel.waypoints.push({})" />
                            </div>
                        </v-form-group>


                        <div>
                            <label class="block text-xs text-blue-gray-500 capitalize font-600 text-left">
                                {{ $t('label.schedule_time') }}
                            </label>

                            <div class="grid grid-cols-6 gap-x-2">
                                <div class="col-span-2 <sm:col-span-6 <sm:mb-4">
                                    <v-form-group>
                                        <v-form-select v-model="schedule_type" :options="['arrival', 'departure']" />
                                    </v-form-group>
                                </div>

                                <div class="col-span-2 <sm:col-span-3">
                                    <v-form-group>
                                        <v-form-calendar v-model="schedule_time" :min-date="new Date(minDate)" />
                                    </v-form-group>
                                </div>

                                <div class="col-span-2 <sm:col-span-3">
                                    <v-form-group>
                                        <v-form-calendar v-model="schedule_time" mode="time" :minute-increment="5" is24hr />
                                    </v-form-group>
                                </div>
                            </div>

                            <div class="my-1 text-xs w-full text-blue-gray-400 mb-4">
                                <template v-if="schedule_type === 'arrival' && form.schedule_departure">
                                    {{ $t('expected_departure', { time: $dayjs(form.schedule_departure).format(display_format) }) }}
                                </template>

                                <template v-else-if="schedule_type === 'departure' && form.schedule_arrival">
                                    {{ $t('expected_arrival', { time: $dayjs(form.schedule_arrival).format(display_format) }) }}
                                </template>
                            </div>
                        </div>

                        <v-form-group v-if="hasAirport" :label="$t('label.flight_number')" class="mb-4">
                            <v-form-input v-model="form.flight_number" />
                        </v-form-group>

                        <v-form-group :label="$t('label.need_seats')" class="mb-4">
                            <v-form-select v-model="form.seats" :options="[1, 2, 3]" :v="v$.form.seats" />
                        </v-form-group>

                        <v-form-group :label="$t('label.comments')" class="mb-4">
                            <v-form-textarea v-model="form.comments" />
                        </v-form-group>
                    </div>
                </div>

                <footer class="flex-shrink-0 px-5 pt-3 border-t-1">
                    <v-button variant="primary" class="w-full" :loading="loading" @click="handleSubmit">
                        {{ $t('action.book') }}
                    </v-button>
                </footer>
            </aside>
        </div>
        
        <login-form v-model="showLoginModal" markup="v-modal" size="sm" @confirm="handleSubmit" @abort="showSignupModal = true; showLoginModal = false;" />
        <signup-form v-model="showSignupModal" markup="v-modal" size="sm" @confirm="showLoginModal = true; showSignupModal = false;" @abort="showLoginModal = true; showSignupModal = false;" />
    </frontoffice-layout>
</template>

<script>
import { i18n } from 'vue-lang-router';

import { useVuelidate } from '@vuelidate/core';
import { required, numeric } from '@vuelidate/validators';
import { mapGetters, mapActions } from 'vuex';

import loginForm from '@/patterns/loginForm';
import signupForm from '@/patterns/signupForm';

export default {
    components: {
        loginForm,
        signupForm
    },

    setup () {
        return { v$: useVuelidate() }
    },

    data: () => ({
        loading: false,
        hasAirport: false,
        showLoginModal: false,
        showSignupModal: false,
        schedule_type: 'departure',
        schedule_time: new Date(),
        display_format: i18n.global.locale == 'fr' ? 'DD MMM YYYY, HH:mm' : 'MMM DD YYYY, hh:mm A',
        travel: {
            pickup_address: {},
            dropoff_address: {},
            waypoints: [],
        },
        form: {
            pickup_address: '',
            dropoff_address: '',
            waypoints: [],
            seats: 1,
            flight_number: '',
            schedule_departure: null,
            schedule_arrival: null,
            comments: null,
        }
    }),

    validations: () => ({
        form: {
            pickup_address: { required },
            dropoff_address: { required },
            seats: { required, numeric },
        }
    }),

    computed: {
        ...mapGetters('member_bookings', [
            'booking',
        ]),

        ...mapGetters('auth', [
            'is_login'
        ]),

        minDate () {
            const startDate = this.$dayjs().add(2, 'hour');
            const minDate = startDate.startOf('day').add(8, 'hour');

            return  startDate.isBefore(minDate) ? minDate : startDate;
        }
    },

    watch: {
        travel: {
            handler(obj) {
                let types = [];

                Object.keys(obj).forEach(key => {
                    if (Array.isArray(obj[key])) {
                        this.form[key] = [];

                        obj[key].forEach(el => {
                            if (!el.address) return;

                            this.form[key].push(el.address);

                            if (el.types) types = types.concat(el.types);
                        });
                    } else {
                        const value = obj[key]?.address || '';
                        this.form[key] = value;

                        if (obj[key]?.types) types = types.concat(obj[key]?.types);
                    }
                });

                this.hasAirport = types.includes('airport');

                this.previewBooking(this.form)
            },
            deep: true
        }  
    },

    mounted () {
        this.$watch((vm) => [vm.schedule_type, vm.schedule_time, vm.booking.duration], () => {
            const duration = this.booking.duration;

            switch (this.schedule_type) {
                case 'arrival':
                    this.form.schedule_departure = duration ? this.$dayjs(this.schedule_time).subtract(duration, 'seconds') : null;
                    this.form.schedule_arrival = this.schedule_time;
                    break;
                default: // departure
                    this.form.schedule_departure = this.schedule_time;
                    this.form.schedule_arrival = duration ? this.$dayjs(this.schedule_time).add(duration, 'seconds') : null;
                    break;
            }
        }, {
            immediate: true,
            deep: true,
        });
    },

    methods: {
        ...mapActions('member_bookings', [
            'previewBooking',
            'createBooking',
        ]),

        handleSubmit () {
			this.v$.form.$touch();

            if (this.v$.form.$invalid) return

            if (!this.is_login) {
                this.showLoginModal = true;
                return;
            } 

            this.createBooking(this.form).then(response => {
                this.$redirect('member-booking-detail', { id: response.data.id })
            }).catch(() => {
                this.loading = false;
            });
        },
    },
};
</script>
